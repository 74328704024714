import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchApplications(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/application/applications', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchApplication(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/application/applications/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addApplication(ctx, applicationData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/application/applications', { application: applicationData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLogins(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/logins/', { params: queryParams })
          .then(response => {
            localStorage.setItem('logins', JSON.stringify(response.data))
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchChecker(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/logins/check', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
